/* eslint-disable no-console */
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';

const noop = (): void => {};

let hasDD = false;

const EMBED_ERRORS = [
  // weird 3rd party errors
  'page-level tag does not work inside iframes',
  'xdm at top',
  'error: s:0 rs:4',
  'safari-extension',
  'cannot redefine property: googletag',
  // 'uncaught {"istrusted":true}',
  'uncaught "wallet not created"',
  'uncaught "empty reason"',
  'uncaught "not implemented on this platform"',
  'uncaught "object not found matching id:1, methodname:update, paramcount:4"',
  'cannot read properties of undefined (reading \'isbroken\')',
];

const BAN_ERRORS = [
  // permission-related dom exceptions
  // 'play() can only be initiated by a user gesture',
  'the play() request was interrupted',
  // 'request was interrupted by a call to pause',
  'is not allowed by the user agent',
  // 'the operation was aborted',
  // 'fullscreen request denied',
  // 'because the user denied permission',
  // 'interact with the document first',

  // third-party script error
  'uncaught "script error."',

  // video player errors
  'videojs: error: videojs-contrib-ads has not seen a loadstart',
  'videojs: error: (code:',

  // network errors
  'httperror: 0 networkerror',
  'httperror: 400',
  'httperror: 401',
  'httperror: 403',
  'httperror: 404',
];

if (process.env.DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_TOKEN,
    forwardErrorsToLogs: true,
    service: 'browser',
    version: process.env.UI_VERSION,
    beforeSend: (log: LogsEvent): false | undefined => {
      // do not track extensions errors
      if (log.error?.stack?.includes('-extension://')) {
        return false;
      }

      // next errors considered as warnings
      if (
        [0, 400, 401, 403, 404].includes(log.http?.status_code)
        || BAN_ERRORS.some((text) => log.message?.toLowerCase().includes(text))
        || (
          log.view?.url.includes('embed.html')
            && EMBED_ERRORS.some((text) => log.message?.toLowerCase().includes(text))
        )
      ) {
        /* eslint-disable no-param-reassign */
        log.status = 'warn';
        log.muted_error = log.error;
        delete log.error;
      }

      return undefined;
    },
  });

  if (process.env.NODE_ENV !== 'production') {
    datadogLogs.logger.setHandler('console');
  }

  hasDD = true;
}

export const logger = (hasDD && datadogLogs.logger)
|| (process.env.NODE_ENV === 'test' && {
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
})
|| console;
